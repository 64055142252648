import Vue from "vue";

export default Vue.extend({
  name: "UbicacionComponent",
  props: {
    lati: {
      type: Number,
      required: true,
    },
    longi: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      center: { lat: 28.659412, lng: -106.088662 },
      markers: [{}],
    };
  },

  mounted() {
    this.addMarker();
  },

  methods: {
    addMarker: function () {
      const marker = {
        lat: this.lati,
        lng: this.longi,
      };
      this.markers.push({ position: marker });
      if (marker.lat != 0 && marker.lng != 0) {
        this.center = marker;
      }
    },
  },
});
